<template>
  <div style="position: relative">
    <!-- Toolbar -->
    <div class="toolbar" style="margin-right: auto">
      <!-- Font Size -->
      <div id="numbs" class="fonts__container">
        <button
          class="fonts__item fonts__item--1"
          @click="execCommand('fontSize', '3', 'Fonts')"
        >
          12
        </button>
        <button
          class="fonts__item fonts__item--2"
          @click="execCommand('fontSize', '4', 'Fonts')"
        >
          14
        </button>
        <button
          class="fonts__item fonts__item--3"
          @click="execCommand('fontSize', '5', 'Fonts')"
        >
          16
        </button>
        <button
          class="fonts__item fonts__item--4"
          @click="execCommand('fontSize', '6', 'Fonts')"
        >
          18
        </button>
      </div>

      <!-- Text Formatting -->
      <div class="box-options">
        <ul class="tool-list">
          <li class="tool">
            <button type="button" @click="execCommand('bold')">
              <b-icon icon="type-bold" />
            </button>
          </li>
          <li class="tool">
            <button type="button" @click="execCommand('italic')">
              <b-icon icon="type-italic" />
            </button>
          </li>
          <li class="tool">
            <button type="button" @click="execCommand('underline')">
              <b-icon icon="type-underline" />
            </button>
          </li>
        </ul>
      </div>

      <!-- Text Alignment -->
      <div class="box-options">
        <ul class="tool-list">
          <li class="tool">
            <button type="button" @click="execCommand('justifyLeft')">
              <i class="fas fa-align-left"></i>
            </button>
          </li>
          <li class="tool">
            <button type="button" @click="execCommand('justifyCenter')">
              <i class="fas fa-align-center"></i>
            </button>
          </li>
          <li class="tool">
            <button type="button" @click="execCommand('justifyRight')">
              <i class="fas fa-align-right"></i>
            </button>
          </li>
        </ul>
      </div>

      <!-- Colors -->
      <div>
        <div class="colors">
          <button
            class="colors__item colors__item--1"
            @click="execCommand('foreColor', '#091E42')"
          ></button>
          <button
            class="colors__item colors__item--2"
            @click="execCommand('foreColor', '#ff5630')"
          ></button>
          <button
            class="colors__item colors__item--3"
            @click="execCommand('foreColor', '#36b37e')"
          ></button>
          <button
            class="colors__item colors__item--4"
            @click="execCommand('foreColor', '#00b8d9')"
          ></button>
        </div>
      </div>
    </div>

    <!-- Editable Content Area -->
    <div
      :ref="`output--${id}`"
      @input="updateContent"
      contentEditable="true"
      :class="`output output--${id}`"
    />

    <div class="audio-controls">
      <button type="button" class="delete-button" @click="deleteRecording">
        <i class="fas fa-trash-alt"></i>
        <!-- Ícone de lixeira -->
      </button>
      <div class="audio-timer" style="min-width: 50px; text-align: center">
        <span>{{ formatRecordingTime }}</span>
        <!-- Exibe o tempo formatado -->
      </div>
      <div v-if="isRecording" class="wave-icon-container">
        <div class="wave"></div>
        <div class="wave"></div>
        <div class="wave"></div>
      </div>
      <button
        type="button"
        :class="{ recording: isRecording, paused: !isRecording }"
        @click="toggleRecording"
      >
        <i :class="isRecording ? 'fas fa-pause' : 'fas fa-microphone'"></i>
      </button>
      <button
        type="button"
        class="send-button"
        :disabled="!canSendMessage"
        @click="sendContent"
      >
        <i class="fas fa-paper-plane"></i>
      </button>
    </div>
  </div>
</template>

<script>
function placeCaretAtEnd(el) {
  el.focus()
  if (window.getSelection && document.createRange) {
    const range = document.createRange()
    range.selectNodeContents(el)
    range.collapse(false)
    const sel = window.getSelection()
    sel.removeAllRanges()
    sel.addRange(range)
  }
}

export default {
  name: 'ClinicReport',
  props: {
    id: {
      type: String,
      required: false
    },
    initialContent: {
      type: String,
      required: false
    },
    contentToAdd: {
      type: String,
      required: false
    }
  },

  data() {
    return {
      content: '',
      editableElement: null,
      isRecording: false,
      recognition: null,
      isPaused: false,
      recordingTime: 0,
      timerInterval: null,
      audioTranscript: '',
      correctedText: ''
    }
  },
  computed: {
    formatRecordingTime() {
      const minutes = Math.floor(this.recordingTime / 60)
      const seconds = this.recordingTime % 60
      return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(
        2,
        '0'
      )}`
    },
    canSendMessage() {
      // Habilita o botão apenas se houver texto reconhecido ou corrigido
      return (
        this.audioTranscript.trim() !== '' || this.correctedText.trim() !== ''
      )
    }
  },

  mounted() {
    this.editableElement = this.$refs[`output--${this.id}`]
    if (this.editableElement) {
      this.editableElement.innerHTML = this.initialContent || ''
      this.focusOnElement()

      this.$root.$on('text-copied', (payload) => {
        this.addTextToEditor(payload)
      })
    }

    const SpeechRecognition =
      window.SpeechRecognition || window.webkitSpeechRecognition

    if (SpeechRecognition) {
      this.recognition = new SpeechRecognition()
      this.recognition.lang = 'pt-BR'
      this.recognition.continuous = true

      this.recognition.onresult = async (event) => {
        const transcript = Array.from(event.results)
          .map((result) => result[0].transcript)
          .join('')
        const correctedText = await this.correctTranscript(transcript)
        return correctedText
      }
    } else {
      this.$toast.warn('SpeechRecognition não é suportado neste navegador.')
    }
  },

  methods: {
    addTextToEditor(copia) {
      if (this.editableElement) {
        const div = document.createElement('div')
        div.innerHTML = copia
        this.editableElement.appendChild(div)
        this.focusOnElement()
      }
    },

    execCommand(command, value = null) {
      document.execCommand('styleWithCSS', false, true)
      document.execCommand(command, false, value)
      placeCaretAtEnd(this.editableElement)
    },

    toggleRecording() {
      if (!this.recognition) {
        this.$toast.warn('Reconhecimento de voz não está disponível.')
        return
      }

      if (this.isRecording) {
        this.recognition.stop()
        this.stopRecordingTimer()
      } else {
        this.recognition.start()
        this.startRecordingTimer()
      }

      this.isRecording = !this.isRecording

      this.recognition.onresult = (event) => {
        this.audioTranscript = Array.from(event.results)
          .map((result) => result[0].transcript)
          .join('')
      }
    },

    startRecordingTimer() {
      if (!this.timerInterval) {
        this.timerInterval = setInterval(() => {
          this.recordingTime++
        }, 1000)
      }
    },

    stopRecordingTimer() {
      if (this.timerInterval) {
        clearInterval(this.timerInterval) // Para o contador
        this.timerInterval = null
      }
    },

    stopAudio() {
      if (this.recognition && this.isRecording) {
        this.recognition.paused()
        this.isRecording = false
        this.isPaused = true
        this.startRecordingTimer() // Reinicia o temporizador
      }
    },

    resumeAudio() {
      if (this.recognition && !this.isRecording && this.isPaused) {
        this.recognition.start()
        this.isRecording = true
        this.isPaused = false
        this.stopRecordingTimer()
      }
    },
    async sendContent() {
      if (!this.audioTranscript.trim()) {
        this.$toast.warn('Nenhum texto para enviar.')
        return
      }

      try {
        // Chamada à API do Google
        const response = await fetch(
          'https://generativelanguage.googleapis.com/v1beta/models/gemini-1.5-flash:generateContent?key=AIzaSyAjHzXJGnU_QX0_3d3exdUlZJpzUEoFQkY',
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              contents: [
                {
                  parts: [
                    {
                      text: `Corrija a ortografia e a pontuação do texto a seguir: "${this.audioTranscript}". Retorne o texto corrigido.`
                    }
                  ]
                }
              ]
            })
          }
        )

        const data = await response.json()
        this.correctedText =
          data?.candidates?.[0]?.content?.parts?.[0]?.text?.trim() || ''

        if (this.correctedText) {
          this.insertTextAtCursor(this.correctedText)
        } else {
          this.$toast.warn('Não foi possível obter uma correção.')
        }
      } catch (error) {
        this.$toast.error('Erro ao corrigir o texto:', error)
      }
    },
    deleteRecording() {
      this.recordingTime = 0
      if (this.timerInterval) {
        clearInterval(this.timerInterval)
        this.timerInterval = null
      }

      if (this.recognition) {
        this.recognition.abort()
      }

      this.isRecording = false
      this.isPaused = false
      this.audioTranscript = ''
      this.correctedText = ''
    },

    insertTextAtCursor(text) {
      if (this.editableElement) {
        this.focusOnElement()
        const range = window.getSelection().getRangeAt(0)
        range.deleteContents()
        range.insertNode(document.createTextNode(text))
        const br = document.createElement('br')
        range.insertNode(br)
        // this.focusOnElement()
        this.updateContent()
        window.getSelection().removeAllRanges()
      }
    },
    focusOnElement() {
      if (this.editableElement) {
        const range = document.createRange()
        range.selectNodeContents(this.editableElement)
        range.collapse(false)
        const selection = window.getSelection()
        selection.removeAllRanges()
        selection.addRange(range)
        this.editableElement.focus()
      }
    },

    updateContent() {
      const content = this.editableElement.innerHTML
      this.content = content
      this.$emit('update-content', content)
    }
  },

  watch: {
    contentToAdd(newContent) {
      this.editableElement.innerHTML += newContent
    }
  }
}
</script>

<style lang="scss" scoped>
.toolbar {
  display: flex;
  padding-top: 8px;
  padding-bottom: 8px;
  background-color: white;
  & > *:not(:last-child) {
    margin-right: 10px;
  }
}

.box-options {
  width: max-content;

  .tool:not(:last-child) {
    border-right: 1px solid #ccc;
    padding-right: 4px;
  }
}

.tool {
  border-right: 1px solid #ccc;
  padding-right: 4px;
  font-size: 18px;
  button {
    background-color: transparent;
    border: none;
  }
}

.tool-list {
  padding: 0;
  margin: 0;
  display: flex;
}
.tool button.recording {
  background-color: #36b37e;
  color: white;
  border: 1px solid #2d8a63;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.fontSize,
.fontFamily {
  border-radius: 50px;
  background-color: white;
  padding: 2px 5px;
}

.output {
  display: block;

  background-color: #fff;
  color: #000;
  /* height: 250px; */
  min-height: 150px; /* Tamanho mínimo aceitável */
  max-height: 50vh; /* Não ocupa mais que 50% da altura da tela */
  overflow-y: scroll;
  border: none;
  padding: 8px;
  border-bottom-left-radius: 16px;

  outline: none;
}

.colors {
  &__item {
    cursor: pointer;
    width: 30px;
    height: 30px;
    margin-right: 10px;
    border-radius: 4px;
    border: none;
  }

  &__item--1 {
    background-color: #091e42;
  }
  &__item--2 {
    background-color: #ff5630;
  }
  &__item--3 {
    background-color: #36b37e;
  }
  &__item--4 {
    background-color: #00b8d9;
  }
}

.fonts {
  &__container {
    padding: 0;
    margin: 0;
    position: relative;
    width: max-content;
    border-right: 1px solid #ccc;
    padding-right: 4px;
  }

  &__text {
    font-size: 18px;
    display: flex;
    align-items: center;
    color: #000;
    p {
      margin: 0;
      margin-right: 2px;
    }
  }
}

.fonts {
  &__container {
    display: flex;
    background-color: white;
  }

  &__item {
    cursor: pointer;
    width: 30px;
    height: 30px;
    margin-right: 10px;
    border-radius: 4px;
    border: none;
  }
}
.audio-controls {
  padding: 0 !important;
  margin: 0 !important;
  align-self: end;
  width: max-content;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  gap: 6px;
  bottom: 4px;
  right: 10px;
}

.audio-controls .send-button {
  background-color: #2d8a63;
  color: white;
  border: none;
  border-radius: 50%;
  padding: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s;
}

.send-button:disabled {
  cursor: not-allowed;
  opacity: 0.5; /* Opcional: para mostrar visualmente que o botão está desabilitado */
  pointer-events: none; /* Remove qualquer interação, incluindo hover */
}
.audio-controls button {
  background-color: transparent;
  border: 0.1px solid #ccc;
  border-radius: 32px;
  margin: 0px;
  width: 32px;
  height: 32px;
  font-size: 16px;
  color: #333;
  cursor: pointer;
}
.audio-timer {
  font-size: 1.2em; /* Aumenta o tamanho da fonte do temporizador */
  font-weight: bold; /* Deixa o temporizador em negrito */
  display: flex;
  padding-right: 10px;
  width: 100px; /* Ajuste conforme necessário */
  text-align: center;
}
/* Estilo para estado de gravação (verde) */
.audio-controls button.recording {
  background-color: #28a745; /* Verde */
  border-color: #28a745;
  color: white;
}

.delete-button {
  font-size: 1.5em; /* Ajusta o tamanho do ícone da lixeira */
  color: #f44336; /* Cor vermelha para o ícone de delete */
  background: none; /* Remove o fundo do botão */
  border: none; /* Remove a borda do botão */
  cursor: pointer; /* Altera o cursor para indicar que o botão é clicável */
}

.delete-button:hover {
  color: #d32f2f; /* Cor do ícone ao passar o mouse (efeito hover) */
}
.wave-icon-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  animation: pulse 1s infinite ease-in-out;
  padding-left: 10px;
}

.wave {
  width: 4px;
  height: 12px;
  background-color: #28a745;
  animation: wave 1.5s infinite;
}

.wave:nth-child(2) {
  animation-delay: 0.3s;
}

.wave:nth-child(3) {
  animation-delay: 0.6s;
}

@keyframes pulse {
  0% {
    transform: translate(-50%, -50%) scale(1);
  }
  50% {
    transform: translate(-50%, -50%) scale(1.3);
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
  }
}

@keyframes wave {
  0% {
    height: 10px;
    opacity: 0.6;
  }
  50% {
    height: 20px;
    opacity: 1;
  }
  100% {
    height: 10px;
    opacity: 0.6;
  }
}

@media (max-width: 600px) {
  .colors {
    display: none;
  }
  #numbs {
    display: none;
  }
  .output {
    height: 14vh;
  }
}

@media (max-width: 1200px) {
  .colors {
    display: none;
  }
  .fonts__container {
    display: none;
  }
}

@media (max-width: 900px) {
  .output {
    height: 40vh;
  }
}

@media (max-width: 850px) {
  .output {
    height: 30vh;
  }
}

@media (max-width: 700px) {
  .output {
    height: 30vh;
  }
}

@media (max-width: 640px) {
  .output {
    height: 20vh;
  }
}
</style>
