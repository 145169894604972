import { render, staticRenderFns } from "./ClinicReport.vue?vue&type=template&id=630a628c&scoped=true"
import script from "./ClinicReport.vue?vue&type=script&lang=js"
export * from "./ClinicReport.vue?vue&type=script&lang=js"
import style0 from "./ClinicReport.vue?vue&type=style&index=0&id=630a628c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "630a628c",
  null
  
)

export default component.exports