<template>
  <div class="module">
    <div class="details">
      <p
        v-if="medicalReportType !== 2"
        :class="
          'details__name' +
          (componentInfo.descMeuModulo.length > 31
            ? ' font-medium'
            : ' font-large')
        "
        :style="`background-color: ${componentInfo.corHexadecimal}`"
      >
        {{ componentInfo.descMeuModulo }}
      </p>
      <div id="check-box-inativos" class="details__checkbox-container">
        <b-form-checkbox
          v-model="showInactivesComplaints"
          :value="true"
          :unchecked-value="false"
        ></b-form-checkbox>
        <span>Exibir inativos</span>
      </div>
      <b-form-input type="date" class="details__date" v-model="date" />

      <b-form-input type="time" class="details__date" v-model="time" />

      <ul class="degree-list">
        <li class="degree-list__item" @click="updateDegree(0, 'Leve')">Leve</li>
        <li class="degree-list__item" @click="updateDegree(1, 'Moderada')">
          Moderada
        </li>
        <li class="degree-list__item" @click="updateDegree(2, 'Severo')">
          Severa
        </li>
      </ul>

      <favorites-select
        :favorites="
          componentInfo.favorites.length &&
          componentInfo.favorites.filter((favorite) => favorite.favorito === 0)
        "
        :with-sub-list="false"
        title-property="descTitulo"
        description-property="descQueixa"
        @on-favorite-chosen="addQuickAccessOrFavoriteToContent"
      />

      <b-icon
        icon="chevron-expand"
        class="module__icon"
        font-scale="2"
        variant="primary"
        style="cursor: pointer"
        @click="toggleExpanded()"
      />

      <b-icon
        icon="three-dots-vertical"
        class="module__icon"
        font-scale="2"
        variant="primary"
        style="cursor: pointer"
      />
    </div>

    <quick-access
      v-if="
        componentInfo.favorites.some((favorite) => favorite.acessoRapido === 0)
      "
      :quick-accesses="
        componentInfo.favorites.length &&
        componentInfo.favorites.filter(
          (favorite) => favorite.acessoRapido === 0
        )
      "
      title-property="descTitulo"
      :with-sub-list="false"
      @on-quick-access-chosen="addQuickAccessOrFavoriteToContent"
    />

    <div class="content" v-if="componentInfo.aberto === 0">
      <clinic-report
        :initial-content="content"
        :content-to-add="contentToAdd"
        @update-content="updateContent"
        :id="`${componentInfo.id}`"
      />
      <time-line
        :data="dataToDisplay"
        id-property="idGrau"
        descProperty="descQueixa"
        :idUser="idMedico"
        :disableFunction="editComplaint"
      />
    </div>
  </div>
</template>

<script>
import QuickAccess from '../../shared/QuickAccess.vue'
import {
  formatDateHour,
  formatDate,
  formatCurrentTime
} from '@/Utils/formatters'
import FavoritesSelect from '../../shared/FavoritesSelect.vue'
import TimeLine from '../../shared/TimeLine'
import ClinicReport from '../../shared/ClinicReport'
import { mapGetters } from 'vuex'

export default {
  name: 'Complaints',
  components: { QuickAccess, FavoritesSelect, TimeLine, ClinicReport },
  props: ['componentInfo', 'idProntuario', 'idPaciente', 'idMedico'],
  mounted() {
    this.recoverComplaintFromCurrentMedicalReport()
  },
  data() {
    return {
      showInactivesComplaints: false,
      efficientUpdateContentInVuex: this.debounce(
        this.updateContentInVuex,
        200
      ),
      isEdditing: false,
      contentToAdd: '',
      content: '',
      degree: 0,
      degreeDescription: 'Leve',
      date: formatDate('', 'en-CA'),
      time: formatCurrentTime()
    }
  },

  computed: {
    ...mapGetters({
      user: 'Setting/user',
      medicalReportType: 'MedicalReports/getMedicalReportType'
    }),
    dataToDisplay() {
      const items = this.showInactivesComplaints
        ? this.componentInfo.data
        : this.filterInactives()

      const data = this.isEdditing
        ? items
        : [
            {
              dtOcorrencia: this.date + 'T' + this.time,
              date: this.date,
              idGrau: this.degree,
              descQueixa: this.content,
              nmProfissional: this.user.nome
            },
            ...items
          ]

      return data
    }
  },

  methods: {
    recoverComplaintFromCurrentMedicalReport() {
      const complaint = this.componentInfo.data.find(
        (complaint) => complaint.idProntuario === this.idProntuario
      )

      if (complaint) {
        this.isEdditing = true
        this.content = complaint.descQueixa
        this.degree = complaint.idGrau
        this.degreeDescription = complaint.descGrau
        this.date = formatDateHour(complaint.dtInclusao)
        this.contentToAdd = complaint.descQueixa

        this.$nextTick(() => {
          this.contentToAdd = ''
        })
      }
    },
    editComplaint(data) {
      this.$store.commit({
        type: 'MedicalReports/editComplaint',
        payload: data
      })
    },
    filterInactives() {
      const filtered = [...this.componentInfo.data].filter((element) => {
        return element.ativo !== 1
      })

      return filtered
    },
    debounce(func, wait) {
      let timeout = null

      return (...args) => {
        if (timeout) {
          clearTimeout(timeout)
        }
        timeout = setTimeout(func, wait)
      }
    },

    updateContentInVuex() {
      if (this.isEdditing) {
        const onlyDate = this.date.split('T')[0] // Caso venha com 'T', pega só a parte da data
        // Obtém a hora atual no formato "HH:mm:ss"
        const currentTime = new Date().toTimeString().split(' ')[0] // Exemplo: "12:34:56"
        this.$store.commit({
          type: 'MedicalReports/editComplaint',
          payload: {
            descQueixa: this.content,
            idProntuario: this.idProntuario,
            idPatient: this.idPaciente,
            idGrau: this.degree,
            descGrau: this.degreeDescription,
            dtInclusao: formatDateHour(),
            dtOcorrencia: `${onlyDate}T${currentTime}`
          }
        })
      } else {
        this.$store.commit({
          type: 'MedicalReports/addComplaint',
          payload: {
            descQueixa: this.content,
            idProntuario: this.idProntuario,
            idPatient: this.idPaciente,
            idGrau: this.degree,
            descGrau: this.degreeDescription,
            dtInclusao: formatDateHour(),
            dtOcorrencia: this.date + 'T' + this.time
          }
        })
      }
    },
    updateContent(complaintData) {
      this.content = complaintData
      this.efficientUpdateContentInVuex()
    },
    updateDegree(degree, description) {
      this.degree = degree
      this.degreeDescription = description

      this.efficientUpdateContentInVuex()
    },
    addQuickAccessOrFavoriteToContent(data) {
      this.contentToAdd = `<div>${data.descQueixa}</div>`
      this.content += `<div>${data.descQueixa}</div>`

      this.efficientUpdateContentInVuex()

      this.$nextTick(() => {
        this.contentToAdd = ''
      })
    },

    toggleExpanded() {
      this.$store.dispatch({
        type: 'MedicalReports/toggleExpandModule',
        payload: this.componentInfo
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../shared/generalModuleStyles.scss';

.content {
  height: 100%;
  display: grid;
  grid-template-columns: repeat(2, 50%);
}
.font-large {
  font-size: 14px;
}

.font-medium {
  font-size: 13px;
}
.module {
  margin: 32px 0;

  &__icon {
    align-self: center;
  }
}
.custom-select {
  max-width: 25%;
}

.details {
  display: flex;

  &__name {
    margin: 0;
    width: 300px;
    color: #fff;
    background-color: #089bab;

    display: flex;
    align-items: center;
    justify-content: center;

    font-weight: 400;

    padding: 0 10px;

    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  &__date {
    background-color: #fff !important;
    display: block;
    width: 135px !important;
    margin: 0 8px;
    line-height: 1 !important;
    height: auto !important;
    font-family: sans-serif;
  }

  &__checkbox-container {
    margin-left: 4px;
    color: #000;
    display: inline-flex;
    align-items: center;
  }
}

.degree-list {
  display: flex;
  padding: 0;
  margin: 0;
  margin-right: auto;
  color: #000;

  &__item {
    cursor: pointer;
    margin: 0 4px;
    border-radius: 10px;
    text-align: center;
    width: 80px;
  }

  &__item:nth-child(1) {
    background-color: #abebc6;
    padding: 8px;
  }
  &__item:nth-child(2) {
    background-color: #f9e79f;
    padding: 8px;
  }
  &__item:nth-child(3) {
    background-color: #f5b7b1;
    padding: 8px;
  }
}

@media (max-width: 1480px) {
  .details {
    &__checkbox-container {
      margin-left: 2px;
      font-size: 11px;
    }
  }
}

@media (max-width: 850px) {
  .degree-list {
    display: none;
  }

  .details {
    align-items: center;
    justify-content: space-between;
  }

  .content {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
}

@media (max-width: 480px) {
  #check-box-inativos {
    display: none;
  }
}
</style>
